import { RootState } from "@/store/store";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const RedirectRoute = () => {
  console.log("redirect   ");
  const { token } = useSelector((state: RootState) => state.auth);
  const isAuthenticated = token ? true : false;
  return <Navigate to={`${isAuthenticated ? "/dashboard/drivers" : "login"}`} replace />;
};

export default RedirectRoute;
