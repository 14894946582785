import ProtectedRoute from "@/utils/ProtectedRoutes";
import { protectedRoutes } from "@/routes/ProtectedRoutes";
import RedirectRoute from "@/utils/RedirectRoute";
import { dashboardRoutes } from "@/routes/DashboardRoutes";
import { publicRoutes } from "@/routes/PublicRoutes";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AuthLayout from "@/Layouts/AuthLayout";
import { Toaster } from "@/components/ui/toaster";

const App = () => {
  const router = createBrowserRouter([
    ...publicRoutes,
    {
      element: <AuthLayout />,
      children: [
        {
          id: "login",
          // element: <Login />,
          lazy: async () => {
            const module = await import("@/Views/Authentication/Login");
            return { element: <module.default /> };
          },
          path: "login",
        },
        {
          id: "register",
          // element: <Register />,
          lazy: async () => {
            const module = await import("@/Views/Authentication/Register");
            return { element: <module.default /> };
          },
          path: "register",
        },
      ],
    },
    {
      element: <ProtectedRoute />,
      children: [
        ...protectedRoutes,
        {
          id: "dashboard",
          path: "/dashboard",
          lazy: async () => {
            const module = await import("@/Layouts/DashboardLayout");
            return { element: <module.default /> };
          },
          children: dashboardRoutes,
          // errorElement:<div>hello</div>
        },
      ],
    },
    {
      path: "*",
      element: <RedirectRoute />,
    },
  ]);

  return (
    <>
      <main className="flex h-screen">
        <RouterProvider router={router} />
      </main>
      <Toaster />
    </>
  );
};

export default App;
