import { RootState } from "@/store/store";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

function ProtectedRoute() {
  const { token } = useSelector((state: RootState) => state.auth);
  const isAuthenticated = token ? true : false;
  return isAuthenticated ? <Outlet /> : <Navigate to="login" replace />;
}

export default ProtectedRoute;
