import { Outlet, Navigate } from "react-router-dom";
import sideImageLight from "@/assets/images/side_image_light.jpg";
import sideImageDark from "@/assets/images/side_image_dark.jpg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
const AuthLayout = () => {
  const { token } = useSelector((state: RootState) => state.auth);
  const isAuthenticated = token ? true : false;
  const [currentTheme, setCurrentTheme] = useState<any>();
  useEffect(() => {
    // Apply saved theme from localStorage or use default
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      // setTheme(savedTheme);
      document.querySelector("html")?.setAttribute("data-theme", savedTheme);
    } else
      document
        .querySelector("html")
        ?.setAttribute("data-theme", "main-theme-light");
    setCurrentTheme(savedTheme);
  }, []);
  return (
    <>
      {isAuthenticated ? (
        <Navigate to="/dashboard/drivers" />
      ) : (
        <>
          <section className="flex flex-1 flex-col justify-center items-center p-10 bg-">
            <Outlet />
          </section>
          <img
            src={
              currentTheme == "main-theme-light"
                ? sideImageLight
                : sideImageDark
            }
            alt="logo"
            className="hidden xl:block h-screen w-1/2 object-cover bg-no-repeat"
          />
        </>
      )}
    </>
  );
};

export default AuthLayout;
